<template>
  <Layout>
    <div class="card py-4">
      <h6 class="text-center mb-3">Inspect driver</h6>
      <div class="text-center mb-2">
        Last ODO Meter Reading
        <strong>{{ currentDriver.vehicles[0].last_odometer_reading }}</strong>
      </div>

      <img
        style="width: 180px; margin: auto; border-radius: 12px"
        :src="currentDriver.vehicles[0].vehicle_image"
        alt="Vehicle image"
      />

      <div class="text-center" v-if="odo_meter.uploading">
        <img
          style="width: 108px; display: block; margin: auto; border-radius: 8px"
          src="@/assets/images/partner/speedo-meter.gif"
          alt=""
          srcset=""
        />
        <strong>Taking ODO Meter reading ...</strong><br />
      </div>

      <div
        style="
          max-width: 320px;
          margin: auto;
          margin-top: 28px;
          margin-bottom: 18px;
        "
      >
        <FormulateForm @submit="submitReading">
          <div class="row">
            <div class="col-sm-6">
              <FormulateInput
                type="number"
                v-model="odo_meter.meter_reading"
                label="ODO Meter count"
                validation-behavior="submit"
                :validation="`required|number|min:${currentDriver.vehicles[0].last_odometer_reading}`"
                :min="currentDriver.vehicles[0].last_odometer_reading"
              />
            </div>
            <div class="col-sm-6">
              <FormulateInput
                type="select"
                :options="rating"
                v-model="odo_meter.rating"
                validation="required"
                label="Condition"
              />
            </div>
          </div>
          <div class="upload-with-preview" @click="selectImage('odometer')">
            <img
              v-if="odo_meter.preview"
              class="previewImg"
              :src="odo_meter.preview"
              alt="Add image"
              srcset=""
            />
            <div v-else>
              <img
                class="take-pic"
                :src="require('@/assets/images/take-photo.jpg')"
                alt="Add image"
              />
              <div class="text-center">ODO Meter</div>
              <div
                v-if="odo_meter.fileHasError"
                class="text-center"
                style="color: red"
              >
                Please upload an image
              </div>
            </div>

            <input
              type="file"
              ref="odometer"
              style="visibility: hidden"
              @input="addImage($event)"
              accept="image/*;capture=camera"
            />
          </div>
          <div class="text-right mt-4">
            <FormulateInput
              type="submit"
              :label="
                odo_meter.updating ? 'Updating Reading...' : 'Update Reading'
              "
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </Layout>
</template>

<script>
import { partnerComputed } from "@/state/helpers";
import Layout from "@/views/layouts/main";
import { getFormData } from "@/utils/createForm";
import { rating } from "@/data/rating";
import { job } from "@/config/api/partner/job";
export default {
  components: {
    Layout,
  },
  created() {
    if (!this.currentDriver) {
      this.$router.go(-1);
    }
  },
  data() {
    return {
      rating: rating,
      odo_meter: {
        ad_id: this.$route.params.id,
        meter_reading: 0,
        file: undefined,
        rating: undefined,
        preview: undefined,
        fileHasError: false,
        updating: false,
      },
    };
  },
  computed: {
    ...partnerComputed,
    current_ad() {
      return this.$route.params.id;
    },
  },
  methods: {
    takeOdoMeterReading() {
      const api = job.inspectCarV2;
      const data = { ...this.odo_meter };
      this.odo_meter.updating = true;
      delete data.preview;
      api.data = getFormData(data);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Odo meter read successfully", {
            title: "Odo Meter reading",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(`Reading failed,${err.response.data.error}!`, {
            title: "Odo Meter reading",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.odo_meter.updating = false;
        });
    },
    addImage($event) {
      let file = $event.target.files;

      if (file && file[0]) {
        this.odo_meter.file = file[0];
        let reader = new FileReader();
        reader.onload = ($event) => {
          this.odo_meter.preview = $event.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    selectImage(val) {
      this.$refs[val].click();
    },
    submitReading() {
      if (!this.odo_meter.file) {
        this.odo_meter.fileHasError = true;
        console.log(this.odo_meter.fileHasError);
        return;
      }
      this.takeOdoMeterReading();
    },
  },
};
</script>

<style scoped>
.previewImg {
  width: 100%;
  height: 100%;
}
.upload-with-preview {
  background-color: #f1f1f1;
  border: 1px dashed black;
  height: 148px;
  margin: 6px;
  overflow: hidden;
  border-radius: 12px;
}
.image-upload-error {
  border: 1px dashed red;
}
.take-pic {
  width: 68px;
  display: block;
  margin: auto;
  margin-top: 24px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
