export const rating = [
  {
    label: "Excellent",
    value: 5,
  },
  {
    label: "Good",
    value: 4,
  },
  {
    label: "Fair",
    value: 3,
  },
  {
    label: "Poor Condition",
    value: 2,
  },
  {
    label: "Bad",
    value: 1,
  },
];
